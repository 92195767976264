import React from "react"
import Layout from "../components/layout"
import '../components/home.scss';
import '../components/christmas.scss';
import image1 from '../assets/christmas/1.jpg';
import image2 from '../assets/christmas/2.jpg';
import image3 from '../assets/christmas/3.jpg';
import image4 from '../assets/christmas/4.jpg';
import image5 from '../assets/christmas/5.jpg';
import image6 from '../assets/christmas/6.jpg';
import image7 from '../assets/christmas/7.jpg';
import image8 from '../assets/christmas/8.jpg';
import image9 from '../assets/christmas/9.jpg';
import image10 from '../assets/christmas/10.jpg';
import image11 from '../assets/christmas/11.jpg';
import image12 from '../assets/christmas/12.jpg';
import image13 from '../assets/christmas/13.jpg';
import image14 from '../assets/christmas/14.jpg';
import image15 from '../assets/christmas/15.jpg';
import image16 from '../assets/christmas/16.jpg';
import image17 from '../assets/christmas/17.jpg';
import image18 from '../assets/christmas/18.jpg';
import image19 from '../assets/christmas/19.jpg';
import image20 from '../assets/christmas/20.jpg';

class Flip1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">1</div>
        <div className="card__face card__face--back">
          <img className="present" src={image1} />
        </div>
      </div>
    )
  }
}

class Flip2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">2</div>
        <div className="card__face card__face--back">
          <img className="present" src={image2} />
        </div>
      </div>
    )
  }
}

class Flip3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">3</div>
        <div className="card__face card__face--back">
          <img className="present" src={image3} />
        </div>
      </div>
    )
  }
}

class Flip4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">4</div>
        <div className="card__face card__face--back">
          <img className="present" src={image4} />
        </div>
      </div>
    )
  }
}

class Flip5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">5</div>
        <div className="card__face card__face--back">
          <img className="present" src={image5} />
        </div>
      </div>
    )
  }
}

class Flip6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">6</div>
        <div className="card__face card__face--back">
          <img className="present" src={image6} />
        </div>
      </div>
    )
  }
}

class Flip7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">7</div>
        <div className="card__face card__face--back">
          <img className="present" src={image7} />
        </div>
      </div>
    )
  }
}

class Flip8 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">8</div>
        <div className="card__face card__face--back">
          <img className="present" src={image8} />
        </div>
      </div>
    )
  }
}

class Flip9 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">9</div>
        <div className="card__face card__face--back">
          <img className="present" src={image9} />
        </div>
      </div>
    )
  }
}

class Flip10 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">10</div>
        <div className="card__face card__face--back">
          <img className="present" src={image10} />
        </div>
      </div>
    )
  }
}

class Flip11 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">11</div>
        <div className="card__face card__face--back">
          <img className="present" src={image11} />
        </div>
      </div>
    )
  }
}

class Flip12 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">12</div>
        <div className="card__face card__face--back">
          <img className="present" src={image12} />
        </div>
      </div>
    )
  }
}

class Flip13 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">13</div>
        <div className="card__face card__face--back">
          <img className="present" src={image13} />
        </div>
      </div>
    )
  }
}

class Flip14 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">14</div>
        <div className="card__face card__face--back">
          <img className="present" src={image14} />
        </div>
      </div>
    )
  }
}

class Flip15 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">15</div>
        <div className="card__face card__face--back">
          <img className="present" src={image15} />
        </div>
      </div>
    )
  }
}

class Flip16 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">16</div>
        <div className="card__face card__face--back">
          <img className="present" src={image16} />
        </div>
      </div>
    )
  }
}

class Flip17 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">17</div>
        <div className="card__face card__face--back">
          <img className="present" src={image17} />
        </div>
      </div>
    )
  }
}

class Flip18 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">18</div>
        <div className="card__face card__face--back">
          <img className="present" src={image18} />
        </div>
      </div>
    )
  }
}

class Flip19 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">19</div>
        <div className="card__face card__face--back">
          <img className="present" src={image19} />
        </div>
      </div>
    )
  }
}

class Flip20 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState(prevState => ({ flipped: !prevState.active }));
  };

  render() {
    const { flipped } = this.state;

    return (
      <div className={`card ${flipped ? "flipped" : "hidden"}`} onClick={this.toggleClass}>
        <div className="card__face card__face--front">20</div>
        <div className="card__face card__face--back">
          <img className="present" src={image20} />
        </div>
      </div>
    )
  }
}


export default function Christmas() {
  return (
    <Layout>
      <div className="ChristmasBackground">
        <div className="scene scene--card">
          <Flip1 />
        </div>
        <div className="scene scene--card">
          <Flip2 />
        </div>
        <div className="scene scene--card">
          <Flip3 />
        </div>
        <div className="scene scene--card">
          <Flip4 />
        </div>
        <div className="scene scene--card">
          <Flip5 />
        </div>
        <div className="scene scene--card">
          <Flip6 />
        </div>
        <div className="scene scene--card">
          <Flip7 />
        </div>
        <div className="scene scene--card">
          <Flip8 />
        </div>
        <div className="scene scene--card">
          <Flip9 />
        </div>
        <div className="scene scene--card">
          <Flip10 />
        </div>
        <div className="scene scene--card">
          <Flip11 />
        </div>
        <div className="scene scene--card">
          <Flip12 />
        </div>
        <div className="scene scene--card">
          <Flip13 />
        </div>
        <div className="scene scene--card">
          <Flip14 />
        </div>
        <div className="scene scene--card">
          <Flip15 />
        </div>
        <div className="scene scene--card">
          <Flip16 />
        </div>
        <div className="scene scene--card">
          <Flip17 />
        </div>
        <div className="scene scene--card">
          <Flip18 />
        </div>
        <div className="scene scene--card">
          <Flip19 />
        </div>
        <div className="scene scene--card">
          <Flip20 />
        </div>
      </div>
    </Layout>
  )
}
